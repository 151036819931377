<template>
  <div>
    <div>
      <div>Bill No : {{ storeBillInfo.billNo }}</div>
      <div class="comapny-name">
        {{ companyInfo.companyName }}
      </div>

      <div class="company-detail">ABN : {{ companyInfo.abn }}</div>

      <div class="company-detail">
        {{ companyInfo.address }}
      </div>
      <div class="company-detail">
        {{ companyInfo.phoneNumber }}
      </div>
    </div>
    <div id="billDetails">
      <div class="order-type-text">
        {{ orderTypeText }}
      </div>

      <div>
        <div class="bill-total">
          <div class="grid-container">
            <div class="grid-item">SUB TOTAL</div>
            <div class="grid-item">:</div>
            <div class="grid-item">
              $ {{ formatPrice(this.storeBillInfo.billTotalWithOutVat) }}
            </div>
          </div>
          <div class="grid-container">
            <div class="grid-item">GST (10%)</div>
            <div class="grid-item">:</div>
            <div class="grid-item">
              $ {{ formatPrice(storeBillInfo.vatTotal) }}
            </div>
          </div>

          <div class="grid-container">
            <div class="grid-item" style="font-weight:bold;">
              Net Total
            </div>
            <div class="grid-item" style="font-weight:bold;">:</div>
            <div class="grid-item" style="font-weight:bold;">
              $ {{ formatPrice(storeBillInfo.billTotal) }}
            </div>
          </div>
          <div>
            <div>
              <qrcode-vue
                v-if="storeBillInfo.billNo"
                render-as="svg"
                :value="storeBillInfo.billNo"
                size="120"
                level="H"
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="bill-table">
          <table id="gitTable">
            <thead>
              <tr>
                <th style="text-align:left;">Item</th>
                <th>QTY</th>
                <th style="text-align:right;">Unit Price</th>
                <th style="text-align:right;">Price</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
          <p id="billProductsTable"></p>
        </div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import Vue from "vue";
Vue.component("qrcode-vue", QrcodeVue);

import MaterialCard from "../components/MaterialCard.vue";

export default {
  components: { MaterialCard },

  data() {
    return {
      cartGst: 0,
      dataReceived: false,
      orderTypeText: "",

      headers: [
        //  { text: "", value: "image" },
        { text: "Item", value: "productDescription" },
        // { text: "Addons", value: "addonItemsName" },
        { text: "Quantity", value: "quantity" },
        { text: "Unit", value: "amount" },
        { text: "Price", value: "actualPrice" }
        // { text: "", value: "delete" }
      ],

      companyInfo: {},
      storeBillInfo: []
    };
  },

  computed: {
    billNumber() {
      return this.$route.params.id;
    }
  },

  created() {
    this.$http.get("/Settings/CompanyInfo").then(response => {
      this.companyInfo = response.data;
    });
    this.getData();
  },
  methods: {
    getData() {
      this.$http.get(`BillHeader/BillNo/${this.billNumber}`).then(response => {
        if (response.data.success) {
          this.storeBillInfo = response.data.data;
          console.log(this.storeBillInfo);
          this.cartGst = this.storeBillInfo.billTotal / 10;

          if (this.storeBillInfo.orderType == 0) {
            this.orderTypeText = "Pick Up";
          }
          if (this.storeBillInfo.orderType == 1) {
            this.orderTypeText = "Delivery";
          }
          if (this.storeBillInfo.orderType == 2) {
            this.orderTypeText = "Dine In";
          }

          const myObj = this.storeBillInfo.billProducts;
          console.log("myObj", myObj);

          let text = "<hr><table>";
          for (let x in myObj) {
            text +=
              "<tr><td align='left'>" +
              myObj[x].productDescription +
              "</td><td align='center'>" +
              myObj[x].quantity +
              "</td><td align='right'>" +
              myObj[x].amount +
              "</td><td align='right'>" +
              myObj[x].actualPrice +
              "</td></tr>";
          }
          text += "</table>";

          document.getElementById("billProductsTable").innerHTML = text;
          this.dataReceived = true;
        }
      });
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }
};
</script>

<style>
.bill-table {
  padding: 5px;
  background-color: rgb(255, 255, 255);
  margin-bottom: 10px;
  /* text-align: center; */
  width: 25%;
  left: 125%;
  transform: translate(150%);
}
th,
td {
  column-width: 150px;
}
.comapny-name {
  padding: 5px;
  text-transform: uppercase;
  margin-bottom: 10px;
  text-align: center;
  font-size: 30px;
  color: black;
  font-weight: bold;
}
.bill-total {
  padding: 5px;
  background-color: rgb(255, 255, 255);
  margin-bottom: 10px;
  text-align: center;
  width: 25%;
  left: 125%;
  transform: translate(150%);
}

.grid-container {
  display: grid;
  grid-template-columns: 50% 5% 45%;

  padding: 5px;
}
.grid-item {
  background-color: rgba(255, 255, 255, 0.8);

  text-align: center;
}
.bill-total-table {
  left: 50%;
  transform: translate(50%);
}
.company-detail {
  padding: 2px;
  text-align: center;
  font-size: 15px;
  color: black;
}
.order-type-text {
  padding: 5px;
  text-transform: uppercase;
  margin-bottom: 10px;
  text-align: center;
  font-size: 20px;
  color: black;
  font-weight: bold;
}
</style>
